// src/TransportationRateForm.js
import React, { useEffect } from 'react';
import { Drawer, Form, Button, Input, InputNumber, message } from 'antd';
import axios from 'axios';
import config from './Config';

const TransportationRateForm = ({ visible, onClose, rate, onSave }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (rate) {
      form.setFieldsValue(rate);
    } else {
      form.resetFields();
    }
  }, [rate, form]);

  const handleFinish = async (values) => {
    try {
      if (rate) {
        // Update transportation rate
        const updatedValues = { ...values, transportationRateId: rate.transportationRateId };
        await axios.put(`${config.apiUrl}/transportationrate/${rate.transportationRateId}`, updatedValues);
        message.success('Transportation rate updated successfully');
      } else {
        // Create new transportation rate
        await axios.post(`${config.apiUrl}/transportationrate`, values);
        message.success('Transportation rate created successfully');
      }
      onSave(); // Refresh the list after save
      onClose(); // Close the drawer
    } catch (error) {
      console.error('There was an error saving the transportation rate!', error);
      message.error('Failed to save transportation rate');
    }
  };

  return (
    <Drawer
      title={rate ? 'Edit Transportation Rate' : 'Create Transportation Rate'}
      width={720}
      onClose={onClose}
      open={visible}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <Form form={form} layout="vertical" onFinish={handleFinish}>
        <Form.Item
          name="description"
          label="Description"
          rules={[{ required: true, message: 'Please enter the description' }]}
        >
          <Input placeholder="Please enter the description" />
        </Form.Item>
        <Form.Item
          name="ratePerKm"
          label="Rate per Km"
          rules={[{ required: true, message: 'Please enter the rate per km' }]}
        >
          <InputNumber
            min={0}
            placeholder="Enter the rate per kilometer"
            style={{ width: '100%' }}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {rate ? 'Update' : 'Create'}
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default TransportationRateForm;
