import React, { useEffect, useState, useRef } from 'react';
import { Table, Input, Button, Space, Popconfirm, message, Typography, Divider, Avatar } from 'antd';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { SearchOutlined, EditOutlined, DeleteOutlined, PlusOutlined, CheckCircleOutlined, ClockCircleOutlined } from '@ant-design/icons';
import config from './Config';

const { Title, Text } = Typography;

const PriceListManager = () => {
  const [priceLists, setPriceLists] = useState([]);
  const [loading, setLoading] = useState(false);
  const searchInputRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchPriceLists();
  }, []);

  const fetchPriceLists = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${config.apiUrl}/pricelists`);
      setPriceLists(response.data);
    } catch (error) {
      console.error('There was an error fetching the price lists!', error);
    } finally {
      setLoading(false);
    }
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInputRef}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInputRef.current.select(), 100);
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const handleDelete = async (priceListId) => {
    try {
      await axios.delete(`${config.apiUrl}/pricelists/${priceListId}`);
      message.success('Price List deleted successfully');
      fetchPriceLists(); // Refresh the price lists
    } catch (error) {
      console.error('There was an error deleting the price list!', error);
      message.error('Failed to delete price list');
    }
  };

  const handleEdit = (priceListId) => {
    navigate(`/pricelist/${priceListId}`);
  };

  const handleAdd = () => {
    navigate('/pricelist');
  };

  const columns = [
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      ...getColumnSearchProps('description'),
    },
    {
      title: 'Last Modified',
      dataIndex: 'lastModified',
      key: 'lastModified',
      responsive: ['md'], // Hide on small screens
      render: (text) => new Date(text).toLocaleString(),
    },
    {
      title: 'Created by',
      dataIndex: 'createdByName',
      key: 'createdByName',
      responsive: ['md'], // Hide on small screens
      ...getColumnSearchProps('createdByName'),
      render: (text) => (
        <>
          <Avatar>{text[0]}</Avatar>
          <Text strong style={{ paddingLeft: 8 }}>{text}</Text>
        </>
      ),
    },
    {
      title: 'Finished',
      dataIndex: 'isFinished',
      key: 'isFinished',
      render: (isFinished) => isFinished ? <><CheckCircleOutlined style={{ color: 'green' }} /></> : <><ClockCircleOutlined /></>,
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <>
          <Space size="middle">
            <Button type="link" icon={<EditOutlined />} onClick={() => handleEdit(record.priceListId)} />
            <Popconfirm
              title="Are you sure you want to delete this price list?"
              onConfirm={() => handleDelete(record.priceListId)}
              okText="Yes"
              cancelText="No"
            >
              <Button type="link" icon={<DeleteOutlined />} />
            </Popconfirm>
          </Space>
        </>
      ),
    },
  ];

  return (
    <>
      <Title level={2}>Price List Manager</Title>
      <Text>Use this page to manage the Price Lists. You can add, edit, copy, and delete Price Lists.</Text>
      <Divider />
      <Button type="primary" icon={<PlusOutlined />} onClick={handleAdd} style={{ marginBottom: 16 }}>
        Create Price List
      </Button>
      <Table dataSource={priceLists} columns={columns} rowKey="priceListId" loading={loading} />
    </>
  );
};

export default PriceListManager;
