// src/ExpenseForm.js
import React, { useEffect } from 'react';
import { Form, Input, Select, Button } from 'antd';

const { Option } = Select;

const ExpenseForm = ({ form, expenseTypes, transportationRates, onSubmit, onCancel, isEditing, initialValues }) => {

  useEffect(() => {
    if (!isEditing) {
      form.resetFields();
    } else {
      form.setFieldsValue(initialValues);
    }
  }, [form, isEditing, initialValues]);

  return (
    <Form form={form} layout="vertical" onFinish={onSubmit}>
      <Form.Item
        name="expenseName"
        label="Short Description"
        rules={[{ required: true, message: 'Please enter the expense name' }]}
      >
        <Input placeholder="Please enter the expense name" />
      </Form.Item>
      <Form.Item
        name="description"
        label="Description"
        rules={[{ required: false }]}
      >
        <Input.TextArea placeholder="Please enter a description" />
      </Form.Item>
      <Form.Item
        name="expenseTypeId"
        label="Expense Type"
        rules={[{ required: true, message: 'Please select an expense type' }]}
      >
        <Select placeholder="Please select an expense type">
          {expenseTypes.map(expenseType => (
            <Option key={expenseType.expenseTypeId} value={expenseType.expenseTypeId}>
              {expenseType.expenseTypeName}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="transportationRateId"
        label="Transportation Rate"
        rules={[{ required: false, message: 'Please select a transportation rate' }]}
      >
        <Select placeholder="Please select a transportation rate">
          {transportationRates.map(rate => (
            <Option key={rate.transportationRateId} value={rate.transportationRateId}>
              {rate.description}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="baseAmount"
        label="Base Amount"
        rules={[{ required: false }]}
      >
        <Input type="number" placeholder="Please enter the base amount" />
      </Form.Item>
      <div style={{ textAlign: 'right' }}>
        <Button onClick={onCancel} style={{ marginRight: 8 }}>
          Cancel
        </Button>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </div>
    </Form>
  );
};

export default ExpenseForm;
